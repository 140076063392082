import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 920px;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 100%;
    padding-left: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    margin-top: -60px;
  }
`;

export const Body = styled.div`
  width: 560px;
  margin: 0 0 -160px;
  transform: translate(0, -140px);
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    width: 100%;
    transform: translate(0, -20px);
    margin-left: -16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    transform: none;
    margin: 0 0 40px;
  }
`;

export const Links = styled.div`
  width: 270px;
  text-align: center;
  transform: translateY(-60px);
  p {
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    font-family: 'Elevon';
    font-size: 12px;
    color: black;
  }
  ul:first-of-type {
    display: inline-flex;
    margin: 0 auto;
    transform: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tabletPor}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
    width: 30%;
    margin: 0 auto;
    transform: translateY(20px);
    p {
      transform: none;
      display: none;
    }
    ul {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
