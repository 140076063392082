import React from 'react';
import { graphql } from 'gatsby';
import { FaSpotify, FaApple } from 'react-icons/fa';

import MainLayout from 'layouts/MainLayout';
import { Banner, Latest, SEO, TextBody } from 'components';
import { InnerWrapCenter } from 'components/common/Containers/styled';
import { StreamingList, StreamingItem } from 'components/Banner/styled';
import { DarkScheme } from 'helpers/navColors';

import { Content, Body, Links } from './styled';

const AlbumTemplate = ({ data }) => {
  const { album, latestAlbums } = data;
  const {
    englishTitle,
    bannerTextColor,
    bannerBackgroundColor,
    metaDescription,
    artists,
    author,
    body,
    artwork,
    releaseDate,
    foreginCollaborator,
    genre,
    label,
    spotify,
    appleMusic,
    bandcamp,
  } = album;

  const bannerConfig = {
    bannerBackgroundColor: bannerBackgroundColor,
    bannerTextColor: bannerTextColor,
    image: artwork,
    title: englishTitle,
    artists: artists,
    date: releaseDate,
    label: label,
    type: 'album',
    foreginCollaborator: foreginCollaborator,
  };

  return (
    <MainLayout navColor={DarkScheme} headerColor={bannerTextColor}>
      <SEO
        title={
          foreginCollaborator !== null
            ? `${englishTitle} - ${artists[0].englishName} & ${foreginCollaborator}`
            : `${englishTitle} - ${artists[0].englishName}`
        }
        description={metaDescription}
        image={artwork.url}
        imageShare={artwork.url}
        isBlogPost
      />

      <Banner {...bannerConfig} />

      <Content>
        <Body>
          <TextBody body={body} artists={artists} authors={author} />
        </Body>

        <Links>
          <p>{label ? label.englishName : 'Self-released'}</p>
          {spotify && (
            <StreamingList isAlbums={true}>
              <StreamingItem>
                <a
                  className='socialMobileItem'
                  href={spotify}
                  target='_blank'
                  rel='noreferrer'
                  role='button'
                  aria-label='Mute volume'
                >
                  <FaSpotify />
                </a>
              </StreamingItem>
              <StreamingItem>
                <a
                  className='socialMobileItem'
                  href={appleMusic}
                  target='_blank'
                  rel='noreferrer'
                  role='button'
                  aria-label='Mute volume'
                >
                  <FaApple />
                </a>
              </StreamingItem>
            </StreamingList>
          )}
        </Links>
      </Content>

      <InnerWrapCenter>
        <Latest data={latestAlbums} sectionTitle='reccs' link='/new-reccs' />
      </InnerWrapCenter>
    </MainLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    album: strapiAlbums(slug: { eq: $slug }) {
      englishTitle
      japaneseTitle
      releaseDate(formatString: "MMMM Do YYYY")
      artists {
        englishName
        slug
      }
      author {
        englishName
        slug
      }
      foreginCollaborator
      body
      artwork {
        url
      }
      label {
        englishName
      }
      genre {
        name
      }
      metaDescription
      spotify
      appleMusic
      bandcamp
      bannerTextColor
      bannerBackgroundColor
    }
    latestAlbums: allStrapiAlbums(
      sort: { fields: releaseDate, order: DESC }
      filter: { slug: { ne: $slug } }
      limit: 4
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
  }
`;

export default AlbumTemplate;
